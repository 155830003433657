import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Divider, Flex, notification } from 'antd';
import { IconAuth2fa, IconNotification, IconPower, IconUserCircle } from '@tabler/icons-react';

import './ProfileMenu.css';
import { requestLogoutAuth } from '~/services/auth';
import { logoutAuthSuccess } from '~/redux/reducer/auth';
import imageAvatarDefault from '~/assets/image/avatar-default.png';

function ProfileMenu() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { currentUser } = useSelector((state: any) => state.auth);

    const handleLogoutAuth = async () => {
        const result = await requestLogoutAuth();

        if (result.status) {
            dispatch(logoutAuthSuccess());
            navigate('/login');
        } else {
            notification.error({ message: 'Thông báo', description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau' });
        }
    };

    return (
        <div className="profile__menu-content">
            <Flex align="center" style={{ padding: '16px 16px 8px 16px' }}>
                <div style={{ width: 45 }}>
                    <Avatar
                        src={imageAvatarDefault}
                        style={{ width: 45, height: 45, lineHeight: 45, background: '#00000040' }}
                        alt="Avatar"
                    />
                </div>

                <div className="ml-2 flex-1">
                    <div style={{ color: '#000' }} className="font-bold">
                        {currentUser?.full_name}
                    </div>
                    <div style={{ fontSize: 'smaller', color: '#000' }}>{currentUser?.email}</div>
                    <div className="profile__menu-status">Manager</div>
                </div>
            </Flex>
            <Divider style={{ margin: '4px 0' }} />

            <ul className="profile__menu-list">
                <li className="profile__menu-item">
                    <IconUserCircle size={18} />
                    <span className="profile__menu-title">Cài đặt tài khoản</span>
                </li>
                <li className="profile__menu-item">
                    <IconAuth2fa size={18} />
                    <span className="profile__menu-title">Xác thực 2 bước</span>
                </li>
                <li className="profile__menu-item">
                    <IconNotification size={18} />
                    <span className="profile__menu-title">Cài đặt thông báo</span>
                </li>
                <Divider style={{ margin: '4px 0' }} />
                <li className="profile__menu-item" onClick={handleLogoutAuth}>
                    <IconPower size={18} />
                    <span className="profile__menu-title">Đăng xuất</span>
                </li>
            </ul>
        </div>
    );
}

export default ProfileMenu;
