import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Fragment, useState, useEffect } from 'react';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { IconTrash, IconArrowLeft, IconInfoCircleFilled } from '@tabler/icons-react';
import { Card, Space, Table, Input, Button, Tooltip, Popconfirm, Pagination, notification, Breadcrumb, Flex, Spin, Switch } from 'antd';

import { convertCurrency } from '~/configs';
import CreatePartner from '../CreatePartner';
import UpdatePartner from '../UpdatePartner';
import IconQuestion from '~/assets/icon/IconQuestion';
import { logoutAuthSuccess } from '~/redux/reducer/auth';
import { requestDestroyPartner, requestGetPartners, requestUpdatePartner } from '~/services/partner';

interface PartnerProps {
    key: string;
    bank_name: string;
    account_number: string;
    account_holder: string;
    partner_name: string;
    partner_key: string;
    partner_url: string;
    balance: number;
}

interface PartnersProps {
    key: string;
    partner_id: number;
    partner_name: string;
    partner_key: string;
    partner_url: string;
    status: boolean;
    created_at: string;
}

function Partners() {
    const [pages, setPages] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [partner, setPartner] = useState<PartnerProps>();
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchValue, setSearchValue] = useState<string>('');
    const [openCreate, setOpenCreate] = useState<boolean>(false);
    const [openUpdate, setOpenUpdate] = useState<boolean>(false);
    const [partners, setPartners] = useState<PartnersProps[]>([]);
    const [page, setPage] = useState<number>(parseInt(searchParams.get('page') || '1'));

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Ha2cm.com - Danh sách đối tác';

        const fetch = async () => {
            setLoading(true);
            const result = await requestGetPartners(page);

            if (result.status === 401 || result.status === 403) {
                navigate('/login');
                dispatch(logoutAuthSuccess());
            } else if (result?.status === 200) {
                setPages(result.pages);
                setPartners(result.data);
            } else {
                notification.error({
                    message: 'Thông báo',
                    description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
                });
            }
            setLoading(false);
        };
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    const handleToggleStatusPartner = async (id: string) => {
        if (!id) {
            return notification.error({
                message: 'Thông báo',
                description: 'Không lấy được ID đối tác cần cập nhật',
            });
        }

        const result = await requestUpdatePartner(id, 'status', {});

        if (result.status === 401 || result.status === 403) {
            navigate('/');
            dispatch(logoutAuthSuccess());
        } else if (result?.status === 200) {
            let clonePartners = [...partners];

            const indexPartner = clonePartners.findIndex((u: any) => u.key === id);
            if (indexPartner !== -1) {
                clonePartners[indexPartner].status = !clonePartners[indexPartner].status;

                setPartners(clonePartners);
                notification.success({
                    message: 'Thông báo',
                    description: 'Bật/Tắt trạng thái đối tác thành công',
                });
            } else {
                notification.error({
                    message: 'Thông báo',
                    description: 'Không tìm thấy đối tác trong danh sách',
                });
            }
        } else {
            notification.error({
                message: 'Thông báo',
                description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
            });
        }
    };

    const confirmDestroyPartner = async (id: string) => {
        if (!id) {
            return notification.error({
                message: 'Thông báo',
                description: 'Không lấy được ID đối tác cần xoá',
            });
        }
        const result = await requestDestroyPartner(id);

        if (result.status === 401 || result.status === 403) {
            navigate('/login');
            dispatch(logoutAuthSuccess());
        } else if (result?.status === 200) {
            const clonePartners = [...partners];

            const indexPartner = clonePartners.findIndex((item) => item.key === id);
            if (indexPartner !== -1) {
                clonePartners.splice(indexPartner, 1);
                setPartners(clonePartners);

                notification.success({
                    message: 'Thông báo',
                    description: result.message,
                });
            } else {
                notification.error({
                    message: 'Thông báo',
                    description: 'Không tìm thấy đối tác trong danh sách',
                });
            }
        } else {
            notification.error({
                message: 'Thông báo',
                description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
            });
        }
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'partner_id',
            key: 'partner_id',
            render: (partner_id: number) => <Fragment>#{partner_id}</Fragment>,
        },
        {
            title: 'Ngân hàng',
            dataIndex: 'bank_name',
            key: 'bank_name',
        },
        {
            title: 'Số tài khoản',
            dataIndex: 'account_number',
            key: 'account_number',
        },
        {
            title: 'Chủ tài khoản',
            dataIndex: 'account_holder',
            key: 'account_holder',
        },
        {
            title: 'Số dư',
            dataIndex: 'balance',
            key: 'balance',
            render: (balance: number) => <Fragment>{convertCurrency(balance)}</Fragment>,
        },
        {
            title: 'Thông tin',
            key: 'partner',
            render: (partner: any) => (
                <Fragment>
                    <span>{partner.partner_name}</span>
                    <br />
                    <span>{partner.partner_url}</span>
                </Fragment>
            ),
        },
        {
            title: 'Trạng thái',
            key: 'status',
            render: (data: any) => (
                <Switch
                    checkedChildren="Bật"
                    unCheckedChildren="Tắt"
                    value={data.status}
                    onChange={() => handleToggleStatusPartner(data.key)}
                />
            ),
        },
        {
            title: 'Ngày tạo/Cập nhật',
            key: 'data',
            render: (data: any) => (
                <Fragment>
                    <span>{moment(data.created_at).format('YYYY-MM-DD HH:mm:ss')}</span>
                    <br />
                    <span>{moment(data.updated_at).format('YYYY-MM-DD HH:mm:ss')}</span>
                </Fragment>
            ),
        },
        {
            title: 'Hành động',
            key: 'action',
            render: (data: any) => (
                <Space>
                    <Tooltip title="Xem chi tiết">
                        <Button
                            className="box-center"
                            type="primary"
                            size="small"
                            onClick={() => {
                                setPartner(data);
                                setOpenUpdate(true);
                            }}
                        >
                            <IconInfoCircleFilled size={18} />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Xoá">
                        <Popconfirm
                            title="Delete?"
                            description={`ID #${data.partner_id}`}
                            onConfirm={() => confirmDestroyPartner(data.key)}
                            okText="Xoá"
                            cancelText="Huỷ"
                            icon={<IconQuestion width={14} height={14} className="mt-1 mr-1" style={{ color: '#ff4d4f' }} />}
                        >
                            <Button danger type="primary" size="small" className="box-center">
                                <IconTrash size={18} />
                            </Button>
                        </Popconfirm>
                    </Tooltip>
                </Space>
            ),
        },
    ];

    return (
        <Space style={{ width: '100%', flexDirection: 'column' }}>
            <Card
                styles={{
                    body: {
                        padding: 12,
                    },
                }}
            >
                <Flex justify="space-between" align="center" className="responsive-flex">
                    <Flex className="gap-2 responsive-item" align="center">
                        <Button size="small" className="box-center" onClick={() => navigate('/')}>
                            <IconArrowLeft size={18} />
                        </Button>
                        <Breadcrumb
                            items={[
                                {
                                    title: <Link to="/">Trang chủ</Link>,
                                },

                                {
                                    title: 'Danh sách đối tác',
                                },
                            ]}
                        />
                    </Flex>
                    <Flex justify="end" className="responsive-item">
                        <Input
                            prefix={<SearchOutlined />}
                            placeholder="Tìm kiếm"
                            value={searchValue}
                            style={{ width: 240 }}
                            className="mx-3"
                            onChange={(e) => setSearchValue(e.target.value)}
                        />
                        <Button style={{ display: 'flex', alignItems: 'center' }} type="primary" onClick={() => setOpenCreate(true)}>
                            <PlusOutlined />
                            Thêm mới
                        </Button>
                    </Flex>
                </Flex>
            </Card>

            {openCreate && <CreatePartner open={openCreate} setOpen={setOpenCreate} callback={partners} setCallback={setPartners} />}
            {openUpdate && partner && (
                <UpdatePartner open={openUpdate} setOpen={setOpenUpdate} callback={partners} setCallback={setPartners} partner={partner} />
            )}

            <Card style={{ minHeight: 'calc(-171px + 100vh)' }}>
                {!loading ? (
                    <Table columns={columns} dataSource={partners} pagination={false} />
                ) : (
                    <Flex align="center" justify="center" style={{ minHeight: '60vh' }}>
                        <Spin />
                    </Flex>
                )}

                {Number(pages) > 1 && (
                    <Flex justify="end" style={{ margin: '20px 0 10px 0' }}>
                        <Pagination
                            current={page || 1}
                            pageSize={20}
                            total={Number(pages) * 20}
                            onChange={(page) => {
                                setPage(page);
                                setSearchParams({ page: page.toString() });
                            }}
                        />
                    </Flex>
                )}
            </Card>
        </Space>
    );
}

export default Partners;
