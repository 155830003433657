import { Fragment } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './style.css';
import './login.css';
import './custom.css';
import { privateRoutes } from './routes';
import DefaultLayout from './layouts/DefaultLayout/DefaultLayout';

function App() {
    return (
        <div className="css-app ant-app">
            <Router>
                <Routes>
                    {privateRoutes.map((route, index: number) => {
                        let Layout = DefaultLayout;

                        if (!route.layout) {
                            Layout = Fragment;
                        }

                        const Page = route.component;
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                element={
                                    <Layout>
                                        <Page />
                                    </Layout>
                                }
                            />
                        );
                    })}
                </Routes>
            </Router>
        </div>
    );
}

export default App;
