import axios from 'axios';

export const baseURL = 'https://ha2cm.com/api';

const request = axios.create({
    baseURL,
    withCredentials: true,
});

export default request;
