import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
    currentUser: object | null;
}

const initialState: AuthState = {
    currentUser: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginAuthSuccess: (state, action: PayloadAction<object>) => {
            state.currentUser = action.payload;
        },
        logoutAuthSuccess: (state) => {
            state.currentUser = null;
        },
    },
});

export const { loginAuthSuccess, logoutAuthSuccess } = authSlice.actions;

export default authSlice.reducer;
