export const convertCurrency = (number: number | null | undefined | string) => {
    if (number == null || number === undefined) {
        return 'Null';
    }

    const amount = Number(number);
    let check = typeof amount === 'number' ? true : false;

    return check ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'đ' : 'Null';
};
