import request from '~/utils';

export const requestGetPartners = async (page: number | string) => {
    try {
        const res = await request.get('/partners', {
            params: {
                page,
            },
        });

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};

export const requestCreatePartner = async (data: object) => {
    try {
        const res = await request.post('/partners/register', data);

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};

export const requestUpdatePartner = async (id: string, type: string, data: object) => {
    try {
        const res = await request.put('/partners/update', data, {
            params: {
                type,
                id,
            },
        });

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};

export const requestDestroyPartner = async (id: string) => {
    try {
        const res = await request.delete('/partners/destroy', {
            params: {
                id,
            },
        });

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};
