import request from '~/utils';

export const requestExportDatabase = async (data: string[]) => {
    try {
        const res = await request.post('/databases/exports', data);

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};
