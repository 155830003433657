import request from '~/utils';

interface TransactionsProps {
    bank_name?: string;
    currency?: string;
    status?: string;
    date_start?: string;
    date_end?: string;
    page: number | string;
    trans_id?: number | string;
    partner_id?: number | string;
}

interface CallbacksProps {
    status?: string;
    date_start?: string;
    date_end?: string;
    page: number | string;
    order_id?: number | string;
    partner_id?: number | string;
    trans_id?: number | string;
}

export const requestGetTransactions = async ({
    bank_name,
    currency,
    status,
    date_start,
    date_end,
    trans_id,
    partner_id,
    page,
}: TransactionsProps) => {
    try {
        const res = await request.get('/transactions', {
            params: {
                page,
                bank_name,
                trans_id,
                partner_id,
                currency,
                status,
                date_start,
                date_end,
            },
        });

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};

export const requestGetCallbacks = async ({ status, date_start, date_end, order_id, partner_id, trans_id, page }: CallbacksProps) => {
    try {
        const res = await request.get('/callback', {
            params: {
                page,
                order_id,
                partner_id,
                trans_id,
                status,
                date_start,
                date_end,
            },
        });

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};

export const requestDestroyTransaction = async (id: string) => {
    try {
        const res = await request.delete('/transactions/destroy', {
            params: {
                id,
            },
        });

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};

export const requestCallbackTransaction = async (id: string) => {
    try {
        const res = await request.get('/transactions/callback', {
            params: {
                id,
            },
        });

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};

export const requestDestroyCallback = async (id: string) => {
    try {
        const res = await request.delete('/callback/destroy', {
            params: {
                id,
            },
        });

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};

export const requestGetStatistics = async () => {
    try {
        const res = await request.get('/statistics');

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};
