import { useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { IconArrowLeft } from '@tabler/icons-react';
import { Link, useNavigate } from 'react-router-dom';
import { Breadcrumb, Button, Card, Col, Flex, Input, Row, Space } from 'antd';

import './database.css';
import ExportItem from './ExportItem';

function ExportDatabase() {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Ha2cm.com - Xuất dữ liệu';
    }, []);

    return (
        <Space style={{ width: '100%', flexDirection: 'column' }}>
            <Card
                styles={{
                    body: {
                        padding: 12,
                    },
                }}
            >
                <Flex justify="space-between" align="center" className="responsive-flex">
                    <Flex className="gap-2 responsive-item" align="center">
                        <Button size="small" className="box-center" onClick={() => navigate('/')}>
                            <IconArrowLeft size={18} />
                        </Button>
                        <Breadcrumb
                            items={[
                                {
                                    title: <Link to="/">Trang chủ</Link>,
                                },

                                {
                                    title: 'Xuất dữ liệu',
                                },
                            ]}
                        />
                    </Flex>
                    <Flex justify="end" className="responsive-item">
                        <Input prefix={<SearchOutlined />} placeholder="Tìm kiếm" style={{ width: 240 }} className="mx-3" />
                    </Flex>
                </Flex>
            </Card>

            <Card style={{ minHeight: 'calc(-144px + 100vh)' }}>
                <Row style={{ margin: '0 -8px', rowGap: 16 }}>
                    <Col md={6} xs={24} style={{ padding: '0 8px' }}>
                        <ExportItem />
                    </Col>
                </Row>
            </Card>
        </Space>
    );
}

export default ExportDatabase;
