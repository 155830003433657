import request from '~/utils';

export const requestLoginAuth = async (user: any) => {
    try {
        const res = await request.post('/auth/login', user);

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};

export const requestGetCurrentAuth = async () => {
    try {
        const res = await request.get('/auth/current-user', {
            params: {
                _v: Math.random(),
            },
        });

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};

export const requestLogoutAuth = async () => {
    try {
        const res = await request.get('/auth/logout');

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};
