import { isMobile } from 'react-device-detect';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, MenuProps, notification } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Avatar, Drawer, Flex, Layout, Menu, Spin, theme } from 'antd';
import { IconAlignLeft, IconBorderAll, IconAlignRight, IconDatabaseCog, IconExchange, IconWebhook } from '@tabler/icons-react';

import './DefaultLayout.css';
import imageLogo from '~/assets/image/thenhanh.png';
import ProfileMenu from '../components/ProfileMenu';
import { requestGetCurrentAuth } from '~/services/auth';
import imageAvatarDefault from '~/assets/image/avatar-default.png';
import { loginAuthSuccess, logoutAuthSuccess } from '~/redux/reducer/auth';

const { Content, Sider, Header } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[], type?: string): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}

const items: MenuItem[] = [
    getItem(<Link to="/">Trang chủ</Link>, '/', <IconBorderAll />),
    getItem('Thống kê', 'sub-2', <IconExchange />, [
        getItem(<Link to="/statistics/callbacks">Thống kê callback</Link>, '/statistics/callbacks'),
        getItem(<Link to="/statistics/transactions">Thống kê giao dịch</Link>, '/statistics/transactions'),
    ]),
    getItem('Đối tác kết nối', 'sub-3', <IconWebhook />, [getItem(<Link to="/partners">Danh sách</Link>, '/partners')]),
    getItem('Database', 'sub-4', <IconDatabaseCog />, [getItem(<Link to="/databases/exports">Xuất dữ liệu</Link>, '/databases/exports')]),
];

interface DefaultLayoutProps {
    children: React.ReactNode;
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children }) => {
    const { pathname } = useLocation();

    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [collapsed, setCollapsed] = useState<boolean>(isMobile);
    const [current, setCurrent] = useState<string>(() => pathname);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { currentUser } = useSelector((state: any) => state.auth);

    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    useEffect(() => {
        const fetch = async () => {
            try {
                const result = await requestGetCurrentAuth();

                if (result.status === 200) {
                    dispatch(loginAuthSuccess(result.data));
                } else {
                    navigate('/login');
                    dispatch(logoutAuthSuccess());
                    notification.error({
                        message: 'Thông báo',
                        description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
                    });
                }
            } catch (error) {
                notification.error({
                    message: 'Thông báo',
                    description: 'Lỗi hệ thống vui lòng thử lại sau',
                });
            } finally {
                setLoading(false);
            }
        };
        fetch();

        const intervalId = setInterval(fetch, 8000);

        return () => clearInterval(intervalId);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleCollapsed = () => {
        if (isMobile) {
            setOpen(!open);
        } else {
            if (collapsed) {
                setCollapsed(false);
                document.querySelector('.default-content')?.classList.remove('active');
            } else {
                setCollapsed(true);
                document.querySelector('.default-content')?.classList.add('active');
            }
        }
    };

    const onClickMenu = (e: any) => {
        setCurrent(e.key);

        if (isMobile) {
            setOpen(false);
        }
    };

    return (
        <Fragment>
            {loading ? (
                <Flex align="center" justify="center" style={{ height: '70vh' }}>
                    <Spin />
                </Flex>
            ) : (
                <Layout className="default_layout">
                    <Header
                        className="default__header"
                        style={{ background: colorBgContainer, width: '100%', paddingLeft: 10, paddingRight: 20 }}
                    >
                        <div className="default__header-hr"></div>
                        <Flex align="center" justify="space-between" style={{ height: '100%' }}>
                            <div className="default__header-logo">
                                <span className="default-btn" onClick={toggleCollapsed}>
                                    {collapsed ? <IconAlignLeft className="text-subtitle" /> : <IconAlignRight className="text-subtitle" />}
                                </span>
                                <Link to="/">
                                    <div className="header__logo-pc">
                                        <img src={imageLogo} alt="Trang chủ" className="header__logo-mobile" />
                                    </div>
                                </Link>
                            </div>

                            <Dropdown
                                dropdownRender={() => (
                                    <Fragment>
                                        <ProfileMenu />
                                    </Fragment>
                                )}
                                placement="bottomLeft"
                                trigger={['click']}
                            >
                                <Avatar
                                    src={currentUser?.avatar_url || imageAvatarDefault}
                                    style={{ cursor: 'pointer', width: 35, height: 35, lineHeight: 35, background: '#00000040' }}
                                />
                            </Dropdown>
                        </Flex>
                    </Header>
                    <Layout>
                        {isMobile ? (
                            <Drawer
                                title={
                                    <Link to="/" onClick={() => setOpen(false)} style={{ userSelect: 'none' }}>
                                        <img src={imageLogo} alt="Trang chủ" className="header__logo-mobile" />
                                    </Link>
                                }
                                open={open}
                                placement="left"
                                onClose={() => setOpen(false)}
                                width={'70%'}
                                style={{ maxWidth: 320, minWidth: 270 }}
                                className="header-drawer"
                            >
                                <Menu
                                    selectedKeys={[current]}
                                    onClick={(e) => onClickMenu(e)}
                                    defaultOpenKeys={['1']}
                                    mode="inline"
                                    className="menu-sidebar"
                                    items={items}
                                />
                            </Drawer>
                        ) : (
                            <Sider
                                trigger={null}
                                collapsible
                                collapsed={collapsed}
                                theme="light"
                                className="default-sider"
                                width={230}
                                style={{
                                    background: colorBgContainer,
                                    minHeight: 'calc(100vh - 116px)',
                                    position: 'fixed',
                                }}
                            >
                                <div className="default-menu">MENU</div>
                                <Menu
                                    selectedKeys={[current]}
                                    onClick={(e) => setCurrent(e.key)}
                                    defaultOpenKeys={['1']}
                                    mode="inline"
                                    items={items}
                                    className="menu-sidebar"
                                    style={{
                                        overflowX: 'hidden',
                                        overflowY: 'auto',
                                        height: 'calc(100vh - 120px)',
                                    }}
                                />
                            </Sider>
                        )}
                        <Content
                            className="default-content"
                            style={{
                                padding: 20,
                                borderRadius: borderRadiusLG,
                                minHeight: 'calc(100vh - 64px)',
                            }}
                        >
                            {children}
                        </Content>
                    </Layout>
                </Layout>
            )}
        </Fragment>
    );
};

export default DefaultLayout;
