import React from 'react';
import Home from '~/layouts/Home';
import NotFound from '~/layouts/NotFound';
import Login from '~/layouts/components/Login';
import Partners from '~/pages/partner/Partners';
import Callbacks from '~/pages/statistics/Callbacks';
import Transactions from '~/pages/statistics/Transactions';
import ExportDatabase from '~/pages/database/ExportDatabase';

interface CustomRouteProps {
    path: string;
    layout?: boolean;
    component: React.ComponentType<any>;
}

export const privateRoutes: CustomRouteProps[] = [
    { path: '/', component: Home, layout: true },
    { path: '*', component: NotFound, layout: false },
    { path: '/login', component: Login, layout: false },
    { path: '/partners', component: Partners, layout: true },
    { path: '/statistics/callbacks', component: Callbacks, layout: true },
    { path: '/databases/exports', component: ExportDatabase, layout: true },
    { path: '/statistics/transactions', component: Transactions, layout: true },
];
