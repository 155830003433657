import moment from 'moment';
import { Table } from 'antd';
import { Fragment } from 'react/jsx-runtime';

import { convertCurrency } from '~/configs';

interface TransactionProps {
    histories: object[];
}

function Transactions({ histories }: TransactionProps) {
    const columns = [
        {
            title: 'ID',
            dataIndex: 'trans_id',
            key: 'trans_id',
            render: (trans_id: number) => <Fragment>#{trans_id}</Fragment>,
        },
        {
            title: 'ID đối tác',
            dataIndex: 'partner_id',
            key: 'partner_id',
            render: (partner_id: number) => <Fragment>#{partner_id}</Fragment>,
        },
        {
            title: 'Ngân hàng',
            dataIndex: 'bank_name',
            key: 'bank_name',
        },
        {
            title: 'Tiền tệ',
            dataIndex: 'currency',
            key: 'currency',
        },
        {
            title: 'Số tiền',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount: number) => {
                let className = '';
                let title = '';
                if (amount >= 0) {
                    title = `+${convertCurrency(amount)}`;
                    className = 'text-success';
                } else {
                    title = convertCurrency(amount);
                    className = 'text-danger';
                }

                return <span className={className}>{title}</span>;
            },
        },
        {
            title: 'Số dư cuối',
            dataIndex: 'balance',
            key: 'balance',
            render: (balance: number) => <Fragment>{convertCurrency(balance)}</Fragment>,
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (created_at: string) => <span>{moment(created_at).format('YYYY-MM-DD HH:mm:ss')}</span>,
        },
    ];
    return (
        <Table
            columns={columns}
            dataSource={histories.map((history, index) => ({ key: index, ...history }))}
            pagination={false}
            scroll={{ y: 440, x: 'max-content' }}
        />
    );
}

export default Transactions;
