import { Link, useNavigate } from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconLock, IconMail } from '@tabler/icons-react';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Card, Flex, Input, Form, Divider, Spin, notification } from 'antd';

import logoGitHub from '~/assets/image/github.png';
import logoGoogle from '~/assets/image/logo-google.png';
import { loginAuthSuccess } from '~/redux/reducer/auth';
import { requestGetCurrentAuth, requestLoginAuth } from '~/services/auth';

function Login() {
    const [loading, setLoading] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);

    const [form] = Form.useForm();
    const { currentUser } = useSelector((state: any) => state.auth);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Ha2cm.com - Đăng nhập quản trị';

        if (currentUser) {
            const fetch = async () => {
                const result = await requestGetCurrentAuth();

                if (result?.status === 200) {
                    dispatch(loginAuthSuccess(result.data));
                    navigate('/');
                    setLoading(true);
                }
            };
            fetch();
        } else {
            setLoading(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLoginEmail = async (data: any) => {
        setLoadingButton(true);
        const result = await requestLoginAuth(data);

        setLoadingButton(false);
        if (result?.status === 200) {
            dispatch(loginAuthSuccess(result.data));
            navigate('/');
            notification.success({
                message: 'Thông báo',
                description: result.message,
            });
        } else {
            notification.error({
                message: 'Thông báo',
                description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
            });
        }
    };

    return (
        <Fragment>
            {loading ? (
                <Flex justify="center" align="center" style={{ height: '100vh' }} className="login">
                    <Card className="login-content">
                        <h3 className="mb-4 font-weight-bold font-size-30">Welcome Back</h3>
                        <p className="mb-6 text-center">Chào mừng bạn trở lại quản lý website!</p>
                        <Form form={form} name="horizontal_login" onFinish={handleLoginEmail}>
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Vui lòng nhập email',
                                    },
                                    {
                                        type: 'email',
                                        message: 'Email không đúng định dạng',
                                    },
                                ]}
                            >
                                <Input size="large" prefix={<IconMail />} placeholder="Nhập địa chỉ email của bạn" />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Vui lòng nhập mật khẩu',
                                    },
                                    {
                                        pattern: /^\S{6,20}$/,
                                        message: 'Mật khẩu không hợp lệ',
                                    },
                                ]}
                                style={{ marginBottom: 12 }}
                            >
                                <Input.Password
                                    size="large"
                                    prefix={<IconLock />}
                                    placeholder="Nhập mật khẩu của bạn"
                                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                />
                            </Form.Item>
                            <Flex justify="end" style={{ marginBottom: 12 }}>
                                <Link
                                    to={'/'}
                                    onClick={() =>
                                        notification.error({ message: 'Thông báo', description: 'Chức năng đang được phát triển' })
                                    }
                                >
                                    Quên mật khẩu?
                                </Link>
                            </Flex>
                            <Form.Item shouldUpdate>
                                {() => (
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        size="large"
                                        disabled={loadingButton}
                                        style={{ width: '100%' }}
                                    >
                                        {loadingButton ? <Spin /> : <Fragment>ĐĂNG NHẬP</Fragment>}
                                    </Button>
                                )}
                            </Form.Item>
                        </Form>
                        <Divider style={{ color: '#7a869a' }}>Hoặc thông qua</Divider>
                        <Flex justify="center" gap={10}>
                            <Button
                                size="large"
                                style={{ display: 'flex', alignItems: 'center', borderRadius: 40 }}
                                onClick={() => notification.error({ message: 'Thông báo', description: 'Chức năng đang được phát triển' })}
                            >
                                <img src={logoGoogle} alt="Google" className="login-google" />
                            </Button>
                            <Button
                                size="large"
                                style={{ display: 'flex', alignItems: 'center', borderRadius: 40 }}
                                onClick={() => notification.error({ message: 'Thông báo', description: 'Chức năng đang được phát triển' })}
                            >
                                <img src={logoGitHub} alt="GitHub" className="login-google" />
                            </Button>
                        </Flex>
                    </Card>
                </Flex>
            ) : (
                <Flex align="center" justify="center" style={{ height: '70vh' }}>
                    <Spin />
                </Flex>
            )}
        </Fragment>
    );
}

export default Login;
